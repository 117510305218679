import type { JSX } from 'react';
import { Video } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type VideoProps = SliceComponentProps<Content.VideoSlice>;

const VideoSlice = ({ slice }: VideoProps): JSX.Element => {
  return <Video {...slice} />;
};

export default VideoSlice;
